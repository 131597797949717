@font-face {
  font-family: "GQD-font";
  src: url("./assets/fonts//GilbertQualifiDemo-nR3R0.ttf");
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
  color: inherit;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.btn {
  padding: 0.9rem 4rem !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
  /* width: fit-content; */
}

.egf-btn-primary {
  background-color: #1f4395 !important;
  color: #ffffff !important;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
}

.egf-btn-secondary {
  background-color: #fab415 !important;
  color: #1f4395 !important;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.25);
}

.egf-header-title,
.egf-title,
.egf-title-2 {
  font-family: "GQD-font";
  font-weight: 400;
}

.egf-header-title {
  font-size: 4.5rem;
}

.egf-title {
  font-size: 3.2rem;
}

.egf-title-2 {
  font-size: 2.5rem;
}

.egf-title-b-border {
  position: relative;
  margin-bottom: 3rem;
}

.egf-title-b-border::before {
  content: "";
  position: absolute;
  bottom: -18px;
  left: 0;
  height: 5px;
  background-color: #fab415;
  width: 100px;
}

.egf-sub-title {
  font-family: "Rubik";
  font-size: 1.25rem;
  font-weight: 400;
}

.egf-pg {
  font-size: 1.2rem;
  font-weight: 300;
}

.egf-header-bg {
  background: #fffaee;
}

.egf-header-bg .details {
  text-align: center;
  width: 85%;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .btn {
    padding: 0.8rem 3rem !important;
    font-size: 1.15rem;
  }

  .egf-header-title {
    font-size: 3.65rem;
  }

  .egf-title {
    font-size: 3rem;
  }

  .egf-title-2 {
    font-size: 2.2rem;
  }

  .egf-pg {
    font-size: 1.05rem;
  }
}

@media screen and (max-width: 991.9px) {
  .egf-header-title {
    font-size: 3.3rem;
  }

  .egf-title {
    font-size: 2.75rem;
  }

  .egf-title-2 {
    font-size: 2rem;
  }

  .egf-title-b-border::before {
    height: 6px;
    width: 85px;
  }

  .egf-sub-title {
    font-size: 1rem;
  }

  .egf-pg {
    font-size: 1rem;
  }
}

@media screen and (max-width: 767.9px) {
  .btn {
    padding: 0.65rem 2.7rem !important;
    font-size: 1rem;
  }

  .egf-header-title {
    font-size: 2.5rem;
  }

  .egf-title {
    font-size: 2.5rem;
  }

  .egf-title-2 {
    font-size: 1.85rem;
  }

  .egf-title-b-border::before {
    height: 5px;
    width: 70px;
  }

  .egf-sub-title {
    font-size: 1rem;
  }

  .egf-pg {
    font-size: 0.95rem;
  }

  .egf-header-bg .details {
    width: 90%;
  }
}
