.coming-soon-text{
    font-size: 3.2rem;
}

.event-card .egf-title{
    font-size: 2.5rem;
}

@media screen and (max-width: 1200px){
    .coming-soon-text{
        font-size: 2.5rem;
    } 

    .event-card .egf-title{
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 767.9px){
    .coming-soon-text{
        font-size: 2rem;
    } 

    .event-card .egf-title{
        font-size: 2rem;
    }
}