.wwd-header .details {
  width: 70%;
}

.scheme-row-item img {
  width: 88%;
}

.scheme-row-item .egf-title {
  font-size: 2.5rem;
}

@media screen and (max-width: 1200px) {
  .scheme-row-item img {
    width: 95%;
  }

  .scheme-row-item .egf-title {
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 767.5px) {
  .wwd-header .details {
    width: 85%;
  }

  .scheme-row-item img {
    width: 100%;
    display: block;
    margin: auto;
  }

  .scheme-row-item .egf-title {
    font-size: 1.7rem;
  }
}
