.trustee-card {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  border: none;
  padding: 3rem;
}

.trustee-card img {
  background: #fdf3da;
  border-radius: 15px;
}

.trustee-card .title {
  font-family: "Rubik";
  font-weight: 600;
  font-size: 2rem;
}

.trustee-card .sub-title {
  font-weight: 400;
  font-size: 1.2rem;
}

.trustee-card .bio {
  font-size: 1rem;
  font-weight: 300;
  line-height: 25px;
}

.trustee-card .btn {
  padding: 0.6rem 2.15rem !important;
  font-size: 1rem !important;
}

@media screen and (max-width: 1200px) {
  .trustee-card {
    border-radius: 15px;
    border: none;
    padding: 2rem;
  }

  .trustee-card .title {
    font-size: 1.7rem;
  }

  .trustee-card .sub-title {
    font-size: 1rem;
  }

  .trustee-card .bio {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 991.9px) {
  .trustee-card {
    border-radius: 15px;
    padding: 1.5rem;
  }

  .trustee-card .title {
    font-size: 1.5rem;
  }

  .trustee-card .bio {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 767.9px) {
  .trustee-card {
    border-radius: 12px;
  }

  .trustee-card .title {
    margin-top: 1.7rem;
  }

  .trustee-card .sub-title {
    font-size: 1rem;
  }

  .trustee-card .bio {
    font-size: 0.9rem;
  }
}
