.h-header {
  position: relative;
}

.h-header::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 100%;
  background: #ffe6af;
}

.hero-text-row {
  position: relative;
}

.hero-text-section .egf-header-title {
  font-size: 4.5rem;
  width: 60%;
}

.hero-text-section p {
  width: 85%;
}

.giving-container,
.giving-container-mobile {
  width: 145px;
  height: 145px;
  border: 3px dashed #fab415;
  position: absolute;
  z-index: 3;
  transition: 0.3s all ease-in;
  right: 0;
  margin-right: -72.5px;
  bottom: 100px;
}

.giving-container,
.giving-container-mobile,
.giving-icon-holder {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.giving-icon-holder {
  background-color: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  min-width: 128px;
  min-height: 128px;
}

.giving-container:hover,
.giving-container-mobile:hover {
  transform: scale(1.07);
}

.giving-container-mobile {
  display: none;
}

.hero-swiper-img-section .mySwiper {
  bottom: -40px;
}

.hero-swiper-img-section img {
  width: 100%;
}

.help-count {
  background: #fab415;
  border-radius: 20px;
  margin: 5rem 0 8rem;
}

.count-details h3 {
  color: #1f4395;
  font-size: 4rem;
  font-weight: 700;
  font-family: "Rubik";
  margin-bottom: 0rem;
}

.count-details p {
  color: #000000;
  font-size: 1.2rem;
  font-weight: 500;
  width: 60%;
  line-height: 1.1;
}

.h-about-section {
  /* background-color: #1f4395; */
  /* background-image: url("../../assets/images/bg/Ellipse-bg-2.png");
  background-repeat: no-repeat;
  background-position: left bottom; */
  color: #000000;
}

.abt-grid-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.abt-grid-container img {
  width: 100%;
}

.abt-grid-container .last-box {
  position: relative;
  top: -25%;
}

.h-about-section .egf-sub-title {
  color: #1f4395;
}

.h-about-section .egf-title {
  width: 70%;
}

/* .img-grid-box{
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 1rem;

} */

.h-our-scheme-section .egf-sub-title {
  color: #1f4395;
}

.h-our-scheme-section .egf-title {
  color: #000000;
  font-size: 3rem;
}

.scheme-card {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  width: 100%;
  min-height: 350px;
  padding: 2rem 1.5rem;
  border: none;
  font-family: "Rubik";
}

.scheme-card .image-holder {
  width: 65px;
  height: 65px;
  background: #fdf3da;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0.5rem auto;
}

.scheme-card h5,
.scheme-card p {
  text-align: center;
}

.scheme-card h5 {
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
}

.scheme-card p {
  font-weight: 300;
}

.h-student-grant-section {
  background-image: url("../../assets/images/bg/Ellipse-bg.png");
  background-repeat: no-repeat;
  background-position: right;
}

.h-student-grant-section p {
  font-family: "Rubik";
}

.h-student-grant-section .bg-img {
  max-width: 100%;
  height: auto;
  width: 500px;
  border-radius: 20px;
}

.h-testimony-section {
  height: 100%;
  background: #fab415;
  border-radius: 15px;
  padding: 5rem;
}

.h-testimony-section .egf-title {
  font-size: 2.5rem;
  color: #fab415;
}

.h-partner-section .egf-sub-title {
  color: #1f4395;
}

.testimonies-header {
  width: 65%;
  margin: 0 auto;
}

.h-testimony-section .testimony-card {
  background-color: #ffffff;
  color: #000000;
  /* box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08); */
  border-radius: 20px;
  padding: 2rem 2.5rem;
  display: flex;
  min-height: 350px;
  flex-direction: column;
}

.testimony-card .about-user-container h5 {
  font-size: 1rem;
  font-weight: 700;
}

.testimony-card .about-user-container p {
  font-size: 0.75rem;
}

.h-partner-section .partner-logo {
  max-width: 75%;
  height: auto;
}

.about-user-container {
  margin-top: auto;
}

.h-testimony-section .swiper {
  z-index: 20 !important;
  padding: 0 1.25rem !important;
  background-color: transparent !important;
}

.h-testimony-section .swiper-button-prev {
  left: -0.5rem;
}

.h-testimony-section .swiper-button-next {
  right: -0.5rem;
}

.h-testimony-section .swiper-button-next,
.h-testimony-section .swiper-button-prev {
  z-index: 1000 !important;
  /* top: unset !important;
  bottom: 10px !important; */
  height: 25px;
  /* background: #ffd881;
  border-radius: 5px;  */
}

.h-testimony-section .swiper-button-next:after,
.h-testimony-section .swiper-button-prev:after {
  font-size: 1.5rem !important;
  color: #1f4395;
  font-weight: 900;
}

@media screen and (max-width: 1200px) {
  .hero-text-section .egf-header-title {
    font-size: 3.75rem;
    width: 70%;
  }

  .hero-text-section p {
    width: 90%;
  }

  .giving-container {
    width: 135px;
    height: 135px;
    right: 0;
    margin-right: -67.5px;
    bottom: 50px;
  }

  .giving-icon-holder {
    min-width: 115px;
    min-height: 115px;
  }

  .h-about-section .egf-title {
    width: 85%;
  }

  .count-details h3 {
    font-size: 3.5rem;
  }

  .count-details p {
    font-size: 1.05rem;
    width: 65%;
  }

  .testimonies-header {
    width: 75%;
  }

  .h-testimony-section .egf-title {
    font-size: 2.35rem;
  }

  .comment-container {
    width: 85%;
  }

  .h-our-scheme-section .egf-title {
    font-size: 2.7rem;
  }

  .scheme-card {
    min-height: 370px;
    padding: 1.2rem 1rem;
  }

  .scheme-card h5 {
    font-size: 1.1rem;
  }

  .scheme-card p {
    font-size: 0.9rem;
  }

  .h-testimony-section {
    background-position: right center;
    background-size: 40%;
    padding: 3.5rem 3.75rem;
  }

  .h-testimony-section .egf-title {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 991.9px) {
  .h-header::after {
    top: unset;
    right: 0;
    left: 0;
    bottom: 4rem;
    width: 100%;
    height: 20%;
  }

  .hero-text-section {
    position: relative;
    z-index: 1;
    text-align: start;
  }

  .hero-text-section .egf-header-title {
    font-size: 3.5rem;
  }

  .hero-text-section h3,
  .hero-text-section p {
    width: 95%;
    margin: 0 auto 0 0;
  }

  .giving-container {
    width: 120px;
    height: 120px;
    left: 50%;
    margin-left: -60px;
    bottom: -600px;
  }

  .giving-icon-holder {
    min-width: 103px;
    min-height: 103px;
  }

  .hero-swiper-img-section img {
    width: 350px;
    margin: auto;
    display: block;
  }

  .count-details {
    margin: auto;
    width: 80%;
  }

  .help-count {
    margin: 4rem 0 7rem;
  }

  .count-details h3 {
    font-size: 2.7rem;
  }

  .count-details p {
    font-size: 1rem;
    width: unset;
  }

  .h-about-section .egf-title {
    width: 100%;
    margin: 0 auto 0 0;
  }

  .testimonies-header {
    width: 85%;
  }

  .h-testimony-section {
    background-position: right center;
    background-size: 30%;
    padding: 3rem 3.5rem;
  }

  .h-testimony-section .egf-title {
    font-size: 2rem;
  }

  .h-testimony-section .testimony-card {
    padding: 1.5rem 2rem;
    min-height: 210px;
  }

  .testimony-card .desc {
    font-size: 0.9rem;
  }

  .testimony-card .about-user-container h5 {
    font-size: 0.95rem;
  }

  .h-student-grant-section .bg-img {
    width: 95%;
    margin: auto;
    display: block;
  }

  .scheme-card {
    min-height: 300px;
  }

  .scheme-card p {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 767.9px) {
  .h-header::after {
    bottom: 2rem;
    height: 18%;
  }

  .hero-text-section .egf-header-title,
  .hero-text-section p {
    margin: 0 auto 0 0;
  }

  .hero-text-section .egf-header-title {
    font-size: 3rem;
    width: 93%;
  }

  .hero-text-section p {
    width: 95%;
  }

  .hero-swiper-img-section img {
    width: 100%;
  }

  .giving-container,
  .giving-container-mobile {
    width: 100px;
    height: 100px;
    left: 50%;
    margin-left: -50px;
    bottom: -590px;
  }

  .giving-container {
    display: none;
  }

  .giving-container-mobile {
    display: flex !important;
    z-index: 2;
    bottom: -60px;
  }

  .giving-icon-holder {
    min-width: 81px;
    min-height: 81px;
  }

  .giving-icon-holder img {
    width: 60%;
  }

  .hero-swiper-img-section .mySwiper {
    bottom: -40px;
  }

  .help-count {
    margin: 3rem 0 5.5rem;
  }

  .count-details {
    margin: 0 auto 2rem;
    width: 100%;
  }

  .count-details p {
    font-size: 0.95rem;
    width: 90px
  }

  .abt-grid-container {
    gap: 0.75rem;
  }

  .h-our-scheme-section .egf-title {
    font-size: 2.1rem !important;
  }

  .h-student-grant-section .bg-img {
    width: 100%;
  }

  .testimonies-header {
    width: 100%;
  }

  .h-testimony-section {
    padding: 1.15rem 0.5rem;
  }

  .h-testimony-section .egf-title {
    font-size: 1.8rem;
  }

  .h-testimony-section .testimony-card {
    padding: 1.2rem 1.5rem;
    min-height: 220px;
    height: 100%;
  }

  .testimony-card .desc {
    font-size: 0.89rem;
  }

  .testimony-card .about-user-container h5 {
    font-size: 0.9rem;
  }

  .testimony-card .about-user-container p {
    font-size: 0.75rem;
  }
}

.swiper-container {
  width: 480px;
}
