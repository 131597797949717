.navbar {
  background: #ffffff;
  padding: 1.1rem 0;
  z-index: 10000;
  /* animation: mymove 0.2s ease-in-out; */
  /* position: -webkit-sticky; */
}
.navbar-brand {
  max-width: 100%;
  height: auto;
  width: 180px;
}
.nav-link {
  font-family: "rubik";
  padding: 0.5rem 1.2rem !important;
  font-size: 1.05rem;
  color: #000000;
  font-weight: 400;
}

.navbar .active{
  color: #FAB415 !important;
}

.navbar .btn {
  padding: 0.6rem 2.7rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

@media screen and (max-width: 991.9px) {
  .navbar-collapse {
    padding: 1.5rem;
  }

  .navbar-collapse ul {
    text-align: center;
  }

  .navbar-collapse .btn {
    width: 100%;
  }

  .navbar-collapse .nav-link {
    padding: 0.75rem 1.2rem !important;
    font-size: 1rem;
  }
}

@media screen and (max-width: 767.9px) {
  .navbar-brand {
    width: 140px;
  }
}

@keyframes mymove {
  from {
    top: -20px;
  }
  to {
    top: 0px;
  }
}