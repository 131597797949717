footer {
  background: #ffe6af;
  padding: 2.5rem 0 2rem;
}

.connect-text {
  font-size: 1.05rem;
  font-weight: 400;
}

.social-icon-container {
  display: flex;
}

.icon-wrapper {
  background: #1f4395;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer-sub-title {
  color: #1f4395;
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 1.1rem;
}

.quik-link-list .list-group-item {
  background: transparent !important;
  padding-left: 0;
  border: none;
  font-size: 0.95rem;
}

.quik-link-list .list-group-item a {
  color: #000000 !important;
}

.footer-logo {
  width: 180px;
  max-width: 100%;
  height: auto;
}

/* .icon-wrapper img{

} */

.lower-footer-section {
  border-top: 0.5px solid #000000;
  padding-top: 1.5rem;
}

.lower-footer-section p,
.lower-footer-section a {
  font-size: 0.875rem;
  color: #212529;
}

@media screen and (max-width: 767.9px) {
  .footer-sub-title {
    font-size: 1.05rem;
    margin-bottom: 0.75rem;
  }

  .quik-link-list .list-group-item {
    font-size: 0.875rem;
    padding: 0.25rem 0;
  }

  .lower-footer-section p {
    font-size: 0.8rem;
  }
}
