.donate-section {
  height: 600px;
  background-image: url("../../assets/images/bg/donate-section-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.donate-section::after {
  content: "";
  background-color: rgba(31, 67, 149, 0.9);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.text-ssection {
  position: relative;
  z-index: 2;
  width: 60%;
  text-align: center;
  color: #ffffff;
  margin: auto;
}

.text-ssection .btn {
  width: 56%;
}

@media screen and (max-width:1200px){
  .text-ssection{
    width: 70%;
  }
}

@media screen and (max-width: 991.9px) {
    .donate-section {
      height: 400px;
    }
  
    .text-ssection {
      width: 80%;
    }
  
    .text-ssection h3 {
      font-size: 2.5rem;
    }
  
    .text-ssection p {
      font-size: 1rem;
    }
  
    .text-ssection .btn {
      width: unset;
    }
  }

@media screen and (max-width: 767.9px) {
  .donate-section {
    height: 420px;
    background-position: center;
    /* background-size: 100% 100%; */
  }

  .text-ssection {
    width: 90%;
  }

  .text-ssection h3 {
    font-size: 2rem;
  }

  .text-ssection p {
    font-size: 0.95rem;
  }

  .text-ssection .btn {
    width: unset;
  }
}
