.abt-header .details .egf-header-title {
  font-size: 3.75rem;
}

.abt-vision-section {
  background: #1f4395;
  color: #ffffff;
}

.abt-vision-section img {
  max-width: 100%;
  height: 100%;
  width: 87%;
}

.abt-vision-section .egf-title {
  font-size: 2.5rem;
}

.close-container {
  margin: -3rem 0 0;
}

.close-container img {
  cursor: pointer;
}

.team-modal {
  top: 80px;
}

.modal-card {
  padding: 2rem;
}

.modal-card .photo {
  display: grid;
  grid-template-rows: 220px 1fr;
  height: 100%;
}

.modal-card .photo img {
  width: 100%;
  height: 220px;
  background: #fdf3da;
  border-radius: 15px;
}

.modal-card .photo .img-overlay {
  margin-top: 2rem;
  background: #ffe6af;
  border-radius: 15px;
  width: 100%;
  height: 100%;
}

.modal-card .text-section {
  padding: 0.5rem 0;
}

.modal-card .text-section .title {
  font-family: "Rubik";
  font-weight: 600;
  font-size: 1.875rem;
}

.modal-card .text-section .sub-title {
  font-weight: 400;
  font-size: 1.2rem;
}

.modal-card .text-section .bio {
  font-size: 1rem;
  font-weight: 300;
  line-height: 25px;
}

@media screen and (max-width: 991.9px) {
  .abt-header .details .egf-header-title {
    font-size: 3.3rem;
  }

  .abt-vision-section .egf-title {
    font-size: 2.2rem;
  }

  .abt-vision-section img {
    width: 100%;
    height: unset;
  }

  .modal-card {
    padding: 0.8rem;
  }

  .modal-card .photo {
    grid-template-rows: 130px 1fr;
  }

  .modal-card .photo img {
    height: 130px;
  }

  .modal-card .photo .img-overlay {
    margin-top: 1rem;
  }

  .modal-card .text-section {
    padding: 0.25rem 0;
  }

  .modal-card .text-section .title {
    font-size: 1.5rem;
  }

  .modal-card .text-section .sub-title {
    font-size: 1rem;
  }

  .modal-card .text-section .bio {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 767.9px) {
  .abt-header .details .egf-header-title {
    font-size: 2.7rem;
  }

  .abt-vision-section .egf-title {
    font-size: 2rem;
  }

  .abt-vision-section img {
    width: 100%;
  }



  .modal-card {
    padding: 0.5rem;
  }

  .modal-card .photo {
    grid-template-rows: unset;
    margin-bottom: 1rem;
  }

  .modal-card .photo img {
    height: 315px;
  }

  .modal-card .photo .img-overlay {
    display: none;
  }

  .modal-card .text-section {
    padding: 0.25rem 0;
  }
}
