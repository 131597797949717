.contact-form-session {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 5rem;
  margin-bottom: 5rem;
}

.contact-form-session .form-control {
  background: #ffffff;
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  color: #000000;
  min-height: 60px;
}

.contact-form-session .form-control:focus {
  box-shadow: none;
}

.contact-form-session .form-label {
  font-size: 1.2rem;
  font-weight: 400;
}

.contact-card {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  min-height: 290px;
  border: none;
}

.contact-card .title {
  color: #1f4395;
  font-size: 1.5rem;
  font-weight: 700;
}
.contact-card p,
.contact-card a {
  color: #000000;
}

.contact-card p {
  font-size: 1.05rem;
  font-weight: 400;
}

.contact-card a {
  text-decoration: none;
}

.icon-holder {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: #fdf3da;
  text-decoration: none;
}

.icon-holder svg path {
  fill: #1f4395;
}

.google-map-container {
  margin: 5rem 0;
}

.google-map-container iframe {
  border-radius: 15px;
  width: 100%;
  height: 550px;
}

.modal-content {
  border-radius: 20px;
  position: relative;
}

.close-modal {
  position: absolute;
  right: 0;
  top: -50px;
  cursor: pointer;
}

.close-modal img {
  height: auto;
  max-width: 100%;
  width: 35px;
}

.messaged-received-modal {
  text-align: center;
  padding: 5.5rem 3.5rem;
}

.messaged-received-modal p {
  font-size: 1.2rem;
  font-weight: 400;
}

.messaged-received-modal h5 {
  font-weight: 700;
  font-size: 1.5rem;
  color: #1f4395;
}

.messaged-received-modal img {
  height: auto;
  max-width: 100%;
  width: 72px;
}

@media screen and (max-width: 991.9px) {
  .contact-card {
    min-height: 230px;
  }

  .contact-card p {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 767.9px) {
  .contact-form-session {
    padding: 2rem;
    margin-bottom: 5rem;
  }

  .contact-form-session .form-control {
    min-height: 55px;
  }

  .contact-form-session .form-label {
    font-size: 1rem;
  }

  .contact-card {
    margin-bottom: 1.5rem;
  }

  .contact-card p {
    font-size: 0.85rem;
  }

  .google-map-container {
    margin: 3rem 0;
  }

  .google-map-container iframe {
    height: 350px;
  }

  .close-modal img {
    width: 30px;
  }

  .messaged-received-modal {
    text-align: center;
    padding: 4rem 3rem;
  }

  .messaged-received-modal p {
    font-size: 1rem;
  }

  .messaged-received-modal h5 {
    font-size: 1.2rem;
  }

  .messaged-received-modal img {
    width: 60px;
  }
}
